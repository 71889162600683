import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import SecondaryHeader from "../components/SecondaryHeader"
import Seo from "../components/Seo"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Tooltip as ReactTooltip } from "react-tooltip"
import { Helmet } from "react-helmet"
import { GatsbySeo } from "gatsby-plugin-next-seo"
const PlsPage = ({ data }) => {
  const cardData = [
    {
      image: data.cardImage1.childImageSharp.fluid,
      title: "Fully Customizable Portal",
      description: "Experience a portal layout designed just for you, ensuring a seamless fit with your brand's identity and operational needs."
    },
    {
      image: data.cardImage1.childImageSharp.fluid,
      title: "Expert-Driven Quality",
      description: "Benefit from our expert design team's precision and experience, backed by our proprietary clear aligner technology."
    },
    {
      image: data.cardImage1.childImageSharp.fluid,
      title: "Efficient Turnaround",
      description: "Fast, reliable, and consistent delivery, keeping pace with your business needs."
    },
    {
      image: data.cardImage1.childImageSharp.fluid,
      title: "Secure and Compliant",
      description: "Uphold the highest standards of data security and patient confidentiality."
    },
    {
      image: data.cardImage1.childImageSharp.fluid,
      title: "Scalable Solutions",
      description: "Grow your business with our adaptable solutions that evolve with your needs."
    },
    {
      image: data.cardImage1.childImageSharp.fluid,
      title: "Transform Your Service Delivery",
      description: "Elevate your brand with AlignerBase's white-label solution. Partner for quality and innovation under your own brand."
    },
  ];

  return (
    <Layout>
      <GatsbySeo
        title="description"
        description="AlignerBase Services - Expertise in Clear Aligner Treatment Planning. Reduce costs by 70%, improve predictability, save time with our experienced team. Discover savings today!"
      />
      <Helmet>
        <meta charset="utf-8" />
      </Helmet>
      <Seo title={`Planning and designing services`} />
      <SecondaryHeader title={<>Private Labelling Solutions</>} />
      <ReactTooltip id="my-tooltip" place="bottom" />

      <div class="container mx-auto">
        <div class="lg:flex">
          <div class="lg:w-1/2">
            <div class="p-4">

              <Img
                style={{ borderRadius: "30px" }}
                fluid={data.teethImg && data.teethImg.childImageSharp && data.teethImg.childImageSharp.fluid}

                alt="Teeth Image"
              />
            </div>
          </div>

          <div class="lg:w-1/2">
            <div class="p-4">
              <h2 className="pb-3">
                Introducing AlignerBase White Labeling
              </h2>
              <p className="text-xl"> Your Brand, Our Expertise
                Empower Your Brand with Customized Clear Aligner Solutions
                At AlignerBase, we're excited to offer a fully tailored white labeling solution for dental professionals and labs. Our service not only integrates with your existing 3D printing capabilities but also provides a fully customized portal layout, specifically designed to meet your unique needs. This is all powered by AlignerBase's time-tested and proven proprietary 3D clear aligner design and delivery service.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container  mx-auto my-8">

      </div>
      <div class="divider-large"></div>
      <div className="container  mx-auto my-8 ">
        <div className="text-center pb-8">
          <h3>Why Choose Our White Labeling Solution?</h3>
        </div>

        <div className="grid lg:justify-center mx-auto sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
          {cardData.map((card, index) => (
            <div key={index} className="bg-white p-4 shadow-md rounded-md">
              <div className="grid grid-cols-5 gap-2">
                <div className="col-span-1">
                <Img fluid={card.image} alt={`Card ${index + 1}`} />
                </div>
                <div className="col-span-4">
                  <h2 className="text-lg font-semibold mb-1">{card.title}</h2>
                  <p>{card.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default PlsPage

export const query = graphql`
  query {
    mainImg: file(relativePath: { eq: "alignerbase-service-img-1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    downArrow: file(relativePath: { eq: "down-arrow.png" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cardImage1: file(relativePath: { eq: "alignerbase-support-topic-heading-img-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    
    teethImg: file(relativePath: { eq: "teeth.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    servicesImgs: allImageSharp(
      sort: { fields: [fluid___originalName], order: ASC }
      filter: {
        fluid: { originalName: { regex: "/alignerbase-home-service-img-*/" } }
      }
    ) {
      edges {
        node {
          id
          fluid(maxWidth: 200, maxHeight: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    servicesFlowImgs: allImageSharp(
      sort: { fields: [fluid___originalName], order: ASC }
      filter: {
        fluid: {
          originalName: { regex: "/alignerbase-service-service-img-*/" }
        }
      }
    ) {
      edges {
        node {
          id
          fluid(maxWidth: 150, maxHeight: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
